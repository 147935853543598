/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.16.14.04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VertexCreateRequest,
  VertexResponse,
} from '../models';
import {
    VertexCreateRequestFromJSON,
    VertexCreateRequestToJSON,
    VertexResponseFromJSON,
    VertexResponseToJSON,
} from '../models';

export interface ApiVertexCreateRequest {
    vertexCreateRequest: VertexCreateRequest;
}

export interface ApiVertexDestroyRequest {
    id: number;
}

export interface ApiVertexListRequest {
    scenario: number;
}

/**
 * 
 */
export class VertexApi extends runtime.BaseAPI {

    /**
     * Endpoint for adding vertices
     */
    async apiVertexCreateRaw(requestParameters: ApiVertexCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VertexResponse>> {
        if (requestParameters.vertexCreateRequest === null || requestParameters.vertexCreateRequest === undefined) {
            throw new runtime.RequiredError('vertexCreateRequest','Required parameter requestParameters.vertexCreateRequest was null or undefined when calling apiVertexCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vertex/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VertexCreateRequestToJSON(requestParameters.vertexCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VertexResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for adding vertices
     */
    async apiVertexCreate(requestParameters: ApiVertexCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VertexResponse> {
        const response = await this.apiVertexCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting edited vertices
     */
    async apiVertexDestroyRaw(requestParameters: ApiVertexDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVertexDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vertex/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting edited vertices
     */
    async apiVertexDestroy(requestParameters: ApiVertexDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVertexDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of vertices
     */
    async apiVertexListRaw(requestParameters: ApiVertexListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VertexResponse>>> {
        if (requestParameters.scenario === null || requestParameters.scenario === undefined) {
            throw new runtime.RequiredError('scenario','Required parameter requestParameters.scenario was null or undefined when calling apiVertexList.');
        }

        const queryParameters: any = {};

        if (requestParameters.scenario !== undefined) {
            queryParameters['scenario'] = requestParameters.scenario;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vertex/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VertexResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of vertices
     */
    async apiVertexList(requestParameters: ApiVertexListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VertexResponse>> {
        const response = await this.apiVertexListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
