/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 25.01.16.14.04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CenterAreaPoint
 */
export interface CenterAreaPoint {
    /**
     * 
     * @type {string}
     * @memberof CenterAreaPoint
     */
    type: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CenterAreaPoint
     */
    coordinates: Array<number>;
}

/**
 * Check if a given object implements the CenterAreaPoint interface.
 */
export function instanceOfCenterAreaPoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function CenterAreaPointFromJSON(json: any): CenterAreaPoint {
    return CenterAreaPointFromJSONTyped(json, false);
}

export function CenterAreaPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): CenterAreaPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function CenterAreaPointToJSON(value?: CenterAreaPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

