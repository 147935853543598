import React from "react";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { TbCheck } from "react-icons/tb";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import ViewCustomLayers from "../../components/ViewCustomLayers";
import { AppStateType } from "../../Types";
import "./CustomAnalysisLayersScreen.css";

import CreateCustomAnalysisLayersScreen from "./CreateCustomAnalysisLayersScreen";
import EditCustomAnalysisLayersScreen from "./EditCustomAnalysisLayersScreen";

interface CustomAnalysisLayersScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class CustomAnalysisLayersScreen extends React.Component<CustomAnalysisLayersScreenProps> {
  render() {
    return (
      <>
        {(() => {
          if (this.props.state.CreateCustomAnalysisLayersPopUpVisible) {
            return (
              <CreateCustomAnalysisLayersScreen
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></CreateCustomAnalysisLayersScreen>
            );
          } else if (this.props.state.EditCustomAnalysisLayersPopUpVisible) {
            return (
              <EditCustomAnalysisLayersScreen
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></EditCustomAnalysisLayersScreen>
            );
          }
        })()}

        <div id="CustomAnalysisLayersScreen">
          <div className="top-bar">
            <ul>
              <li>
                <MenuTitle title="Eigen bestemmingenlagen" />
              </li>
              {(() => {
                if (this.props.state.customAnalysisLayersLoading) {
                  return (
                    <li>
                      <div className="spinner">
                        <div className="pin"></div>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <div className="upToDate">
                        <TbCheck />
                      </div>
                    </li>
                  );
                }
              })()}
              <li>
                <MainButton
                  onClick={() => {
                    this.props.stateHandler.initCreateCustomAnalysisLayersScreen(
                      this.props.updateState
                    );
                  }}
                  icon={<FaPlus size={12} />}
                  className="MainBtn"
                >
                  Nieuwe bestemmingenlaag
                </MainButton>
              </li>
            </ul>
          </div>
          <div id="CustomAnalysisLayersScreen-ContentHolder">
            <div id="CustomAnalysisLayersScreen-ResultHolder">
              {this.props.state.customAnalysisLayers.map(
                ({ id, name, destinations, type }, index) => {
                  return (
                    <div
                      key={id}
                      className="CustomAnalysisLayersScreen-ResultItem"
                    >
                      <div className="CustomAnalysisLayersScreen-ResultItemTitle">
                        {name.toLocaleUpperCase()}
                      </div>
                      <ViewCustomLayers
                        points={destinations.map((destination) => {
                          return destination.geom;
                        })}
                      />
                      <div className="CustomAnalysisLayersScreen-Tab">
                        <div className="tooltip">
                          <button
                            className="CustomAnalysisLayersScreen-Icon-button"
                            onClick={() => {
                              this.props.stateHandler.DeleteCustomAnalysisLayer(
                                id,
                                this.props.updateState
                              );
                            }}
                          >
                            <MdDelete size={15} />
                          </button>
                          <span className="tooltiptext">Verwijderen</span>
                        </div>
                        <div className="tooltip">
                          <button
                            className="CustomAnalysisLayersScreen-Icon-button"
                            onClick={() => {
                              this.props.stateHandler.initEditCustomAnalysisLayersScreen(
                                { id, name, destinations, type },
                                this.props.updateState
                              );
                            }}
                          >
                            <MdEdit size={15} />
                          </button>
                          <span className="tooltiptext">Bewerken</span>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
