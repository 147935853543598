import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class AdminStateHandler {
  // Admin screen
  initAdminScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.loadLogs(updateState);
    this.state.screenState = ScreenState.AdminScreen;

    updateState(this.state);
  }

  loadLogs(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.listLogs = [];
    this.logsApi.apiLogsList().then((response) => {
      this.state.listLogs = response;
      updateState(this.state);
    });
  }
}
