import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { AuthUsersCreateRequest } from "../../client/lib/apis/UsersApi";
export class UsersStateHandler {
  // Admin screen
  initUsersScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.UsersScreen;
    this.state.newUser.email = "";
    this.state.newUser.password = "";
    this.state.newUser.area_id = null;
    this.state.listUsers = [];
    this.loadUsers(updateState);
    updateState(this.state);
  }

  updateNewUser(
    this: AppStateHandler,
    newUser: {
      email?: string;
      password?: string;
      area_id?: number;
    },
    updateState: (newState: AppStateType) => void
  ) {
    if (newUser.email) this.state.newUser.email = newUser.email;
    if (newUser.area_id) this.state.newUser.area_id = newUser.area_id;
    if (newUser.password) this.state.newUser.password = newUser.password;
    updateState(this.state);
  }

  createNewUser(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    const authUsersCreateRequest: AuthUsersCreateRequest = {
      user: {
        id: 0,
        email: this.state.newUser.email || "",
        password: this.state.newUser.password || "",
        area: this.state.newUser.area_id || 0,
        isActive: true,
      },
    };
    this.usersApi
      .authUsersCreate(authUsersCreateRequest)
      .then(() => {
        this.initUsersScreen(updateState);
      })
      .catch((error) => {
        console.log("Error while creating new user:", error);
      });
    updateState(this.state);
  }

  loadUsers(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.usersApi
      .authUsersList()
      .then((response) => {
        this.state.listUsers = response;
        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while loading users:", error);
      });
  }
}
