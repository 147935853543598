import React from "react";
import { FaPlus } from "react-icons/fa";
import { GoXCircleFill } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import SectionTitle from "src/components/SectionTitle";
import { TextInput, TextInputType } from "src/components/TextInput";
import CreateCustomAnalysisLayersScreen from "src/screens/conf/CreateCustomAnalysisLayersScreen";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import { ScenarioTypeEnum } from "../../client/lib/models";
import "./ProjectScreen.css";

interface ProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ProjectScreen extends React.Component<ProjectScreenProps> {
  render() {
    return (
      <>
        {(() => {
          if (this.props.state.CreateCustomAnalysisLayersPopUpVisible) {
            return (
              <CreateCustomAnalysisLayersScreen
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              ></CreateCustomAnalysisLayersScreen>
            );
          }
        })()}
        <div id="ProjectScreen">
          <div className="top-bar">
            <ul>
              <li>
                <MenuTitle
                  title={this.props.state.projectinfo.name || "Project"}
                />
              </li>
              <li>
                <MainButton
                  onClick={() => {
                    this.props.stateHandler.updateProject(
                      this.props.updateState
                    );
                  }}
                  icon={<MdOutlineDone size={12} />}
                  className="MainBtn OK"
                >
                  Opslaan
                </MainButton>
              </li>
              <li>
                <MainButton
                  onClick={() => {
                    this.props.stateHandler.deleteProject(
                      this.props.updateState
                    );
                  }}
                  icon={<GoXCircleFill size={12} />}
                  className="MainBtn Danger"
                >
                  Project verwijderen
                </MainButton>
              </li>
            </ul>
          </div>
          <div id="ProjectScreen-ContentHolder">
            <div className="row">
              <div>
                <SectionTitle subtitle={true}>Naam</SectionTitle>
                <div>
                  <TextInput
                    type={TextInputType.TextSpacesNumbersLines}
                    placeholder="Naam"
                    onChange={(newValue) => {
                      this.props.stateHandler.projectInfoUpdateForm(
                        { projectName: newValue },
                        this.props.updateState
                      );
                    }}
                    value={this.props.state.projectinfo.name}
                  ></TextInput>
                </div>
              </div>
            </div>
            <div className="row">
              <div>
                <SectionTitle subtitle={true}>Type Scenario</SectionTitle>
                <div>
                  <TextInput
                    type={TextInputType.TextSpacesNumbersLines}
                    placeholder="Type Scenario"
                    onChange={(newValue) => {}}
                    value={
                      this.props.state.InitSenarioTypes.find(
                        (item) =>
                          item.value ===
                          this.props.state.projectinfo.scenarioType
                      )?.label
                    }
                  ></TextInput>
                </div>
              </div>
            </div>
            {(() => {
              if (
                this.props.state.projectinfo.scenarioType ===
                ScenarioTypeEnum.BicycleOrientedDevelopment
              ) {
                return (
                  <div className="row">
                    <div>
                      <SectionTitle subtitle={true}>Bestemmingen</SectionTitle>
                      <div>
                        {(() => {
                          if (
                            this.props.state.newProject.destinationGroupList
                              .length === 0
                          ) {
                            return (
                              <div className="spinner">
                                <div className="pin"></div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col">
                                {this.props.state.newProject.destinationGroupList.map(
                                  (destinationGroup) => (
                                    <div
                                      key={destinationGroup.id}
                                      className="row"
                                    >
                                      <label
                                        className={`CheckBoxButton ${
                                          destinationGroup.active
                                            ? ""
                                            : "disabled"
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          value={destinationGroup.value}
                                          checked={this.props.state.projectinfo.destinationGroup.includes(
                                            destinationGroup.id
                                          )}
                                          onChange={() => {
                                            this.props.stateHandler.projectInfoUpdateForm(
                                              {
                                                destinationGroup_id:
                                                  destinationGroup.id,
                                              },
                                              this.props.updateState
                                            );
                                          }}
                                        />
                                        {destinationGroup.label}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            );
                          }
                        })()}
                        <div>
                          <MainButton
                            onClick={() => {
                              this.props.stateHandler.initCreateCustomAnalysisLayersScreen(
                                this.props.updateState
                              );
                            }}
                            icon={<FaPlus size={12} />}
                            className="MainBtn"
                          >
                            Nieuwe bestemmingenlaag
                          </MainButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </>
    );
  }
}
