import * as L from "leaflet";
import React from "react";
import { ContentTypeEnum } from "src/client/lib/models";
import { v4 as uuidv4 } from "uuid";

import "./ResultBlockMap.css";
interface Geometry {
  coordinates: [number, number][][] | [number, number][][][];
  type: "Polygon" | "MultiPolygon";
  name: string;
}

interface ResultBlockOverviewMapProps {
  geoJsonString: string;
  mapType: ContentTypeEnum;
}

export class ResultBlockOverviewMap extends React.Component<ResultBlockOverviewMapProps> {
  map?: L.Map;
  mapHolder: React.RefObject<HTMLDivElement>;
  mapId: string;

  overviewColor: string = "#5d87ff";
  neighborhoodsColor: string = "#000000";

  overviewGroup: L.FeatureGroup<any> = L.featureGroup();

  constructor(props: any) {
    super(props);
    this.mapHolder = React.createRef();
    this.mapId = uuidv4();
  }

  render() {
    return <div ref={this.mapHolder} className="ResultBlockMap" />;
  }

  zoomToFeatureGroup(
    map: L.Map,
    featureGroup: L.FeatureGroup,
    fallbackLatitude?: number | null,
    fallbackLongitude?: number | null
  ) {
    if (featureGroup.getLayers().length > 0) {
      map.fitBounds(featureGroup.getBounds());
    } else {
      map.setView([fallbackLatitude || 52.09, fallbackLongitude || 5.11], 7);
    }
  }

  parseOverviewJsonString(geojson: string): Geometry[] {
    let returnData: Geometry[] = [];

    try {
      // parse json
      const FeatureCollection = JSON.parse(geojson);
      // validate data then return
      if ("features" in FeatureCollection && "type" in FeatureCollection) {
        FeatureCollection.features.forEach((feature: any) => {
          const GEOMETRY = JSON.parse(feature.geometry);

          returnData.push({
            coordinates: GEOMETRY.coordinates,
            type: GEOMETRY.type,
            name: feature.properties.name,
          });
        });
      }

      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return returnData;
    }
  }

  drawOverview() {
    if (this.map && this.props.mapType === ContentTypeEnum.Overview) {
      this.overviewGroup.clearLayers();

      // From string to overview object
      const overviewobjects = this.parseOverviewJsonString(
        this.props.geoJsonString
      );
      // Add the isochrones to the map
      if (overviewobjects?.length > 0) {
        overviewobjects.forEach((feature) => {
          L.geoJSON(feature, {
            style: {
              color:
                feature.name !== "overview"
                  ? this.neighborhoodsColor
                  : this.overviewColor,
              opacity: 1,
              weight: feature.name !== "overview" ? 0.1 : 1.5,
              lineCap: "round",
              lineJoin: "round",
              fillColor:
                feature.name !== "overview"
                  ? this.neighborhoodsColor
                  : this.overviewColor,
              fillOpacity: feature.name !== "overview" ? 0.1 : 0.3,
            },
            onEachFeature: (feature: any, layer) => {
              if (feature.name && feature.name !== "overview") {
                layer.bindTooltip(feature.name, {
                  permanent: false,
                  direction: "center",
                  className: "polygon-label",
                  opacity: 1,
                });
              }
            },
          }).addTo(this.overviewGroup);
        });
      }

      // Zoom to layers if there are any
      this.zoomToFeatureGroup(this.map, this.overviewGroup);
    }
  }
  componentDidMount() {
    if (
      this.mapHolder.current != null &&
      this.mapHolder.current.childNodes.length === 0
    ) {
      // Initialize map
      const mapElement = document.createElement("div");
      mapElement.style.height = "100%";
      mapElement.style.width = "100%";
      mapElement.id = this.mapId;
      this.mapHolder.current.appendChild(mapElement);

      this.map = L.map(this.mapId, {
        zoomControl: false,
        attributionControl: true,
        zoomSnap: 0.5,
        minZoom: 7,
        maxZoom: 15,
      });
      this.map.setView([52.09, 5.11], 7);
      // Add layers
      let layer = L.tileLayer(
        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        {
          opacity: 1,
        }
      );
      layer.addTo(this.map);

      this.overviewGroup.addTo(this.map);
    }

    this.drawOverview();
  }

  componentDidUpdate() {
    this.drawOverview();
  }
}
